<template>
  <div>
    <fill-profile ref="enterProfileDetails" @profileCreated="profileCreated()"></fill-profile>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="mainContent" v-else>
      <b-container>
        <b-row>
          <!-- <b-col md="3 d-flex justify-content-center" id="mobileView">
            <the-sidebar ref="side"></the-sidebar>
          </b-col> -->
          <b-col md="12 d-flex justify-content-center">
            <!-- <div class="options">
              <h4 class="text-center" style="font-size: 18px; font-weight: 600">
                Your Institution Dashboard is here
              </h4>
              <b-list-group class="text-center mt-4">
                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-book-open fa-2x"></i>
                      <p>Courses</p></span
                    >
                  </div>
                </b-list-group-item>

                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-calendar-alt fa-2x"></i>
                      <p>Timetable</p></span
                    >
                  </div>
                </b-list-group-item>

                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-book-reader fa-2x"></i>
                      <p>Live Class</p></span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-calendar-check fa-2x"></i>
                      <p>Attendance</p></span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-book fa-2x"></i>
                      <p>Resources</p></span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-copy fa-2x"></i>
                      <p>Assignment</p></span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item href="#">
                  <div>
                    <span
                      ><i class="fas fa-edit fa-2x"></i>
                      <p>Assessment</p></span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item :to="{ name: 'sms' }">
                  <div>
                    <span
                      ><i class="fas fa-sms fa-2x"></i>
                      <p>SMS</p></span
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div> -->
            <br />
            <!-- <b-modal
              id="userEnrolled"
              hide-footer
              hide-header
              size="md"
              centered
              ref="modal"
            >
              <h5 class="text-center">Enrolled Successfully</h5>
            </b-modal> -->
            <div>
              <!-- <h5 class="font-weight-bolder">Enter the enrollment code to proceed</h5> -->
              <!-- <span class="text-danger text-center" v-if="profileError">
                Please update your personal
                <router-link :to="{ name: 'profile' }">profile</router-link> before
                institute enrollment!
              </span> -->
              <!-- <b-form id="EnrollmentCode" @submit.prevent="enrollUser()">
                <b-form-group>
                  <b-input
                    name="txtName"
                    placeholder="Enrolment Code"
                    v-model="enrollmentCode.invitation_code"
                    :class="{ 'is-invalid': responseErrors.invitation_code }"
                  ></b-input>
                  <span class="text-danger" v-if="responseErrors.invitation_code">{{
                    responseErrors.invitation_code[0]
                  }}</span>
                </b-form-group>
                <b-form-group>
                  <b-button
                    type="submit"
                    variant="info"
                    size="md"
                    :disabled="enrollBtn.disabled"
                    >{{ enrollBtn.text }}</b-button
                  >
                </b-form-group>
              </b-form> -->
              <h4 class="text-dark text-center font-weight-bolder">
                No posts to show currently
              </h4>
            </div>

            <!-- <input
              type="text"
              placeholder="Start Post"
              class="form-control p-3"
              disabled
            />
            <br />
            <div class="userPost">
              <p class="text-center text-warning">No Posts to show!</p>
              <b-list-group>
                <b-list-group-item>
                  <div class="userDetails">
                    <span class="userImage d-flex"
                      ><i class="fas fa-user-circle fa-2x" style="align-self: center"></i>
                      <div class="ml-2">
                        <p class="m-0 p-0">Arvind</p>
                        <p class="m-0 p-0">Group Name</p>
                      </div></span
                    >

                    <p style="align-self: flex-end">|Subgroup Name</p>
                  </div>
                  <p class="postDetail mt-4">
                    An exam has been scheduled for Semester 5, Ekectronics on 05/02/2021.
                  </p>
                  <p class="postResponds">150 Responds | 25 Comments</p>
                  <div class="postButtons">
                    <b-button-group>
                      <b-button><i class="fas fa-reply-all"></i> Respond</b-button>
                      <b-button
                        ><i class="far fa-thumbs-up"></i>

                        Like</b-button
                      >
                      <b-button
                        ><i class="far fa-comment-dots"></i>

                        Comment</b-button
                      >
                      <b-button
                        ><i class="fas fa-share-alt"></i>

                        Share</b-button
                      >
                      <b-button
                        ><i class="far fa-paper-plane"></i>

                        Send</b-button
                      >
                    </b-button-group>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div> -->
          </b-col>
          <!-- <b-col md="3" id="mobileView">
            <the-notifications></the-notifications>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import Navigation from "../components/templates/Navigation/Navigation.vue";
// import Notifications from "../components/templates/Dashboard/Notifications.vue";
// import Sidebar from "../components/templates/Dashboard/Sidebar.vue";
// import CheckUserIsVerified from "../components/templates/Dashboard/CheckUserIsVerified.vue";
import axios from "axios";

export default {
  async created() {
    this.fetchData = true;
    await this.getVerificationStatus();
    if (this.verified) {
      const existingInvitation = await localStorage.getItem("invitation");
      if (existingInvitation) {
        return this.$router.push({
          name: "invitation",
          params: { code: existingInvitation },
        });
      }

      const token = await localStorage.getItem("token");
      if (!token) {
        this.logoutUser();
      }
      await this.getInstitutions();
      await this.getUserDetails();

      if (this.institutions.length > 0) {
        await this.institutionUsers();
      }
      this.$router.push({ name: "institute" });
      this.fetchData = false;
    }
  },
  data() {
    return {
      userId: "",
      profileInfo: {
        fName: "",
        lName: "",
        dob: "",
      },
      verified: false,
      institutions: [],
      enrollmentCode: {},
      fetchData: false,
      responseErrors: {},
      enrollBtn: {
        text: "Proceed",
        disabled: false,
      },
      profileError: false,
      institutionUsersList: [],
    };
  },
  components: {
    // "the-navigation": Navigation,
    // "the-notifications": Notifications,
    // "the-sidebar": Sidebar,
    // "user-verified": CheckUserIsVerified,
  },
  methods: {
    async logoutUser() {
      await alert("Something went wrong! Please login again.");
      this.$router.push({ name: "login" });
    },
    async getVerificationStatus() {
      try {
        const url = this.$store.getters.getAPIKey.userDetails;
        const response = await axios.get(url);
        this.verified = true;
        response;
      } catch (error) {
        if (error.response == 403) {
          this.verified = false;
        }
      }
    },
    //Emit RESPONSE
    profileCreated() {
      if (
        this.enrollmentCode.invitation_code &&
        this.enrollmentCode.invitation_code != ""
      ) {
        this.enrollUser();
      }
    },
    async enrollUser() {
      this.enrollBtn.text = "Please wait...";
      this.enrollBtn.disabled = true;
      const url = this.$store.getters.getAPIKey.acceptInvitation;
      await axios
        .post(url, this.enrollmentCode)
        .then(() => {
          this.enrollmentCode = {};
          this.$bvModal.show("userEnrolled");
          this.created();
          // this.$router.push({ name: "institute" });
          this.clearResponse();
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          if (error.response.status == 423) {
            this.$refs.enterProfileDetails.checkProfile();
          }
          this.clearResponse();
        });
    },
    async getInstitutions() {
      // this.fetchData = true;
      await axios
        .get(this.$store.getters.getAPIKey.mainAPI + `/institutions`)
        .then((response) => {
          this.institutions = response.data;
        });
    },
    async institutionUsers() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionusers/myinstitutions`;
      await axios.get(url).then((response) => {
        if (response) {
          this.institutionUsersList = response.data;
        }
      });
    },
    async getUserDetails() {
      try {
        const url = this.$store.getters.getAPIKey.userDetails;
        const response = await axios.get(url);
        if (response) {
          this.userId = response.data.id;
        }
      } catch (error) {
        if (error.response) {
          const userDetailsProblem = "Something Went Wrong";
          alert(userDetailsProblem);
          this.logoutUser();
        }
      }
    },
    clearResponse() {
      this.enrollBtn.text = "Proceed";
      this.enrollBtn.disabled = false;
      setTimeout(() => {
        this.responseErrors = {};
      }, 3000);
    },
  },
};
</script>

<style >
.mainContent {
  padding: 0px 0px 45px 0px;
  background-color: #eeeeeb;
}

.profile {
  width: 250px !important;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.profile .input-group span {
  position: absolute;
  right: 50px;
  height: 25px;
  z-index: 2;
}

.profile input {
  height: 35px;
  border-style: none;
  background-color: #eef3f8;
  position: relative;
}

.profile .details p {
  font-size: 14px;
  font-family: "Roboto Regular";
  font-weight: 600;
  color: #5b5b5b;
}

.tasks h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto Regular";
}

.tasks a {
  font-size: 14px;
  font-family: "Roboto Regular";
}

.profile .details p:first-child,
.profile .subDetails p {
  font-size: 14px !important;
  font-family: "Roboto Regular";
}

.profile .subDetails p {
  font-size: 14px;
  color: #0066a6;
  font-weight: 600;
}

.errspan {
  right: 17px;
  position: relative;
  z-index: 2;
  background-color: #707070 !important;
  right: 14px;
  margin-left: auto;
  color: white !important;
}

.options {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px;
}

.options .list-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.options .list-group-item,
.notifications .list-group-item,
.onlineCoursesForYou .list-group-item,
.tasks .list-group-item {
  border-style: none;
  padding: 4px;
}

.options .list-group-item p,
.notifications .list-group-item p,
.onlineCoursesForYou .list-group-item p,
.tasks .list-group-item p {
  font-family: "Roboto Regular";
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.col-md-7 input {
  border-radius: 5px;
  height: 45px;
}

.userPost .userDetails {
  font-family: "Roboto Regular";
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 14px;
  font-weight: 600;
}

.userPost .postDetail {
  font-size: 16px;
  font-weight: 300;
}

.postButtons button {
  background-color: #ffffff;
  color: #706f6f;
  border-style: none;
  font-family: "Roboto Regular";
  font-size: 14px;
}

.userPost p {
  font-family: "Roboto Regular";
  font-size: 14px;
}

.notifications,
.onlineCoursesForYou,
.tasks {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
}

.notifications h6,
.onlineCoursesForYou h6,
.tasks h6 {
  font-family: "Roboto Regular";
  font-size: 16px;
  font-weight: 600;
}

.notifications a,
.onlineCoursesForYou a,
.tasks a {
  font-size: 14px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  body {
    overflow-x: hidden;
  }

  #mobileView {
    display: none !important;
  }

  #chooseInstituteMobile {
    display: block !important;
  }

  .options .list-group {
    flex-wrap: wrap;
  }

  .options .list-group-item {
    width: 100px;
  }

  .postButtons .btn-group {
    display: flex;
    flex-wrap: wrap;
  }

  .mainContent .container-fluid {
    padding: 0px 20px 0px 20px !important;
  }

  [class*="col-"] {
    margin-bottom: 15px;
  }
}
</style>
